.EachProductDiv {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #DCDCE6;
  display: flex;
  flex-direction: column;
}

.EachProductInner {
  display: flex;
  flex-direction: row;
  margin: 35px 0px 35px 0px;
}

.EachProductInner img {
  width: 300px;
  flex-shrink: 0;
}

.EachProductText {
  width: 100%;
  padding: 4px 0px 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.EachProductText .title {
  font-size: 22px;
  font-weight: bold;
}

.EachProductText .desc {
  font-size: 18px;
  color: #8F969F;
}

.EachProductText .price {
  width: 100%;
  text-align: right;
  color: #3386FF;
  font-size: 24px;
  font-weight: bold;
}

.moreButton {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 20px 10px 20px;
  color: #8F969F;
  border: 1px solid #DCDCE6;
  border-bottom: 0;
  cursor: pointer;
  font-size: 18px;
}

.buttonShow {
  background: #8F969F;
  color: white;
}

.scheduleDiv {
  width: 100%;
  display: none;
  border-bottom: 1px solid #DCDCE6;
}

.showDiv {
  display: flex !important;
}

.noSchedule {
  width: 100%;
  text-align: center;
  padding: 30px;
  font-size: 20px;
}

.scheduleEachDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.scheduleInfoDiv {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 300px;
}

.scheduleTitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.scheduleInfo {
  font-size: 18px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.reserveBtn {
  margin-left: -80px;
  font-size: 22px;
  color: white !important;
  background: #3386FF;
  padding: 10px 25px 10px 25px;
  cursor: pointer;
}