.headerDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  align-items: center;
}

.littleLinkDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 13px;
}

.littleLine {
  width: 1px;
  height: 14px;
  background: #9A9A9A;
}

.littleLink a {
  font-size: 14px;
  color: #9A9A9A;
}

.middleDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 30px 0px;
}

.middleDiv img {
  width: 210px;
  height: 70px;
  object-fit: contain;
}

.navDiv {
  width: 100vw;
  height: 65px;
  border-top: 1px solid #DCDCE6;
  border-bottom: 1px solid #DCDCE6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navInner {
  width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Style The Dropdown Button */
.dropbtn {
  padding: 16px;
  font-size: 18px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  margin-top: 14px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent a {
  width: 100%;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #DCDCE6;
}

.searchDiv {
  width: 525px;
  height: 45px;
  border: 2px solid #3386FF;
  border-radius: 32px;
  padding: 0px 20px 0px 20px;
}

.searchDiv form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.searchDiv button {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;
}

.searchDiv button svg {
  width: 100%;
  height: 100%;
  fill: #3386FF;
}

.searchDiv input {
  width: 100%;
  height: 100%;
  border: 0;
}

.searchDiv input:focus {
  outline: none;
}