.headerDiv {
    box-sizing: border-box;
    background: white;
    width: 100vw;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 12px #0000001F;
    z-index: 20;
}

.searchOpenHeader {
    box-shadow: none;
}

.logoImg {
    width: 107px;
    height: 30px;
}

.iconsDiv {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 6px;
}

.iconsDiv a {
    padding: 0;
    margin: 0;
    line-height: 0;
}

.iconsDiv:first-child {
    justify-content: flex-start;
}

.iconBtn {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
}

.iconSvg {
    width: 30px;
    height: 30px;
}

.searchOpenHeader .searchBtn svg {
    fill: #3386FF !important;
}

.searchDiv {
    width: 100vw;
    height: 0;
    background: #3386FF;
    box-shadow: 0px 0px 20px #00000066;
    overflow: hidden;
    transition: height 0.3s;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px;
}

.searchOpen {
    height: 65px !important;
}

.searchWhiteDiv {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    background: white;
    border-radius: 23px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.searchWhiteDiv form {
    width: 100%;
    display: flex;
}

.searchWhiteDiv input {
    border: 0 solid black !important;
    outline: none;
    width: 100%;
    flex-shrink: 1;
}

.searchWhiteDiv svg {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    fill: #9A9A9A;
}

.searchWhiteDiv button {
    margin-left: 7px;
    width: 30px;
    height: 30px;
}