.loginDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.titleDiv {
    margin-top: 50px;
    margin-bottom: 56px;
    font-size: 20px;
    color: #191919;
}

.loginDiv form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 50px;
}

.loginDiv input {
    width: 100%;
    height: 45px;
    border: 1px solid #DCDCE6;
    padding: 15px;
}

.loginDiv form button {
    width: 100%;
    background: #3386FF;
    border-radius: 3px;
    height: 45px;
    font-size: 16px;
    color: white !important;
}

.loginLoading form button {
    background: darkgray;
}

.loginDiv form div {
    width: 100%;
    color: red;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    word-break: break-all;
}

.socialButton {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 3px;
    margin-top: 10px;
}

.socialButton svg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.socialButton div {
    flex-shrink: 1;
    width: 100%;
}

.socialNaver {
    background: #03C75A;
    color: white !important;
}

.socialKakao {
    background: #FEE500;
    color: #000000D9 !important;
}

.socialButton img {
    width: 100%;
    height: auto;
}

.linksDiv {
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.link {
    width: 100%;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkLine {
    flex-shrink: 0;
    width: 1px;
    background: #C9C9C9;
}