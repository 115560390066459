button {
    padding: 0;
    border: 0;
    background: none;
}

.topbarWrapper {
    width: 100vw;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #DCDCE6;
}

.noUnderLine {
    border-bottom: 0 !important;
}

.topbarDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.titleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
}

.simple .titleDiv {
    padding-right: 30px;
}

.titleBlankDiv {
    width: 20px;
}

.title {
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.titleDiv svg {
    width: 20px;
    height: 20px;
    fill: #9A9A9A;
    margin-left: 5px;
    transition: transform 0.3s;
}

.topbarShow .titleDiv svg {
    transform: rotate(-180deg);
}

.beforeBtnDiv, .changeBtnDiv {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.beforeBtnDiv {
    left: 0;
}

.beforeBtn {
    height: 30px;
}



.beforeBtn svg {
    height: 30px;
    fill: #9A9A9A;
}

.changeBtnDiv {
    right: 0;
    align-items: flex-end;
}

.changeBtn {
    height: 34px;
    background: #F8F8FA;
    border: 1px solid #DCDCE6;
    border-radius: 17px 17px 0px 0px;
    padding-left: 17px;
    padding-right: 17px;
    box-sizing: border-box;
    font-size: 14px;
}

.secondCateDiv {
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    background: #F8F8FA;
    border-bottom: 1px solid #DCDCE6;
    max-height: 190px;
    overflow: hidden;
    transition: max-height 0.3s, padding-top 0.3s, padding-bottom 0.3s;
}

.secondCateDiv:not(.show) {
    max-height: 0px !important;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
}

.secondCateTableDiv {
    width: 100%;
    border: 1px solid #DCDCE6;
    border-radius: 8px;
    overflow: hidden;
}


.secondCateDiv table {
    width: 100%;
    border: 1px solid #DCDCE6;
    border-radius: 8px;
    overflow: hidden;
    background: white;
    border-collapse: collapse;
}

.secondCateDiv td{
    border: 1px solid #DCDCE6;
    width: 50%;
    height: 45px;
    text-align: center;
}

.secondCateDiv table a {
    width: 100%;
    height: 100%;
}

.secondCateDiv table a div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    color: #0052C8;
    font-weight: 700;
}

.modalDiv {
    position: fixed;
    width: 100vw;
    overflow: hidden;
    z-index: 13;
    height: calc(100% - 90px) !important;
    bottom: -110vh;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px #00000033;
    border-radius: 30px 30px 0px 0px;
    box-sizing: border-box;
    transition: bottom 0.3s;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.showModal {
    bottom: 0;
}


.modalTopDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    margin-bottom: 30px;
}

.modalTopDiv div {
    font-size: 18px;
}

.modalTopDiv svg {
    width: 24px;
    height: 24px;
    fill: #8F969F;
}

.modalContentsDiv {
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-y: auto;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.modalContentsDiv::-webkit-scrollbar {
    display: none;
}

.modalBtn {
    flex-shrink: 0;
    height: 45px;
    width: calc(100vw - 160px);
    background: #425571;
    border-radius: 23px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalBtnSelected {
    background: #3386FF !important;
}

.modalBtn div {
    color: white;
    font-size: 15px;
}

.cateLine {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #EEEEF2;
    margin-top: 18px;
    margin-bottom: 18px;
}

.modalFirstDiv {
    width: 100%;
}

.modalFirstCateBtn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    box-sizing: border-box;
    flex-shrink: 1;
}

.modalFirstCateBtn div {
    color: #4F5968;
    font-size: 16px;
}

.modalFirstCateBtn svg {
    width: 20px;
    fill: #DCDCE6;
}

.modalSecondDiv {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    height: 0px;
    transition: height 0.3s;
}

.modalFirstOpen .modalSecondDiv {
    height: 40px;
}

.modalSecondDiv::-webkit-scrollbar {
    display: none;
}

.modalSecondDiv button {
    flex-shrink: 0;
    background: #FFFFFF;
    height: 30px;
    border: 1px solid #B6BAC0;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 8px;
    margin-top: 10px;
}

.modalSecondSelected {
    background: #3386FF !important;
    box-shadow: 0px 0px 10px #0052C847 !important;
    border: 1px solid #3386FF !important;
}

.modalSecondDiv button div {
    font-size: 14px;
    color: #95999F;
}

.modalSecondSelected div {
    color: white !important;
}

@media (min-width: 1000px) {
    .beforeBtn {
        display: none;
    }

    .topbarWrapper {
        width: 100%;
        height: 80px;
    }

    .title {
        font-size: 22px !important;
        font-weight: bold;
    }
}
