.myResDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 100%;
}

.loadmore {
    width: 150px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
    border: 1px solid #DCDCE6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noload {
    color: gray !important;
}

.eachResDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    --each-res-color: black;
    border-bottom: 1px solid #EEEEF2;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.eachResDiv:last-of-type {
    border-bottom: 0 !important;
}

.active {
    --each-res-color: #FF6479 !important;
}

.inactive {
    --each-res-color: #B6BAC0 !important;
}


.resNumDiv {
    display: flex;
    margin-bottom: 15px;
    margin-top: 10px;
}

.resNumTitle, .resNumNum {
    border: 1px solid var(--each-res-color);
    padding: 7px 10px 7px 10px;
}

.resNumTitle {
    color: var(--each-res-color);
}

.resNumNum {
    color: white;
    background-color: var(--each-res-color);
}

.eachResTitle {
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #191919;
    margin-bottom: 25px;
}

.eachResInfoDiv {
    margin-bottom: 12px;
    margin-left: 10px;
    display: flex;
}

.eachResInfoTitle {
    color: #4F5968;
    margin-right: 15px;
    width: 80px;
}

.eachResInfoColor {
    color: var(--each-res-color)
}

.myResDetailDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 100%;
    margin-bottom: 20px;
}

.myResDetailInner {
    width: 100%;
    --each-res-color: black;
}

.priceDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    row-gap: 10px;
}
  
.priceDiv span {
    color: #FF4E40;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
}

.payBtn {
    font-size: 18px;
    width: 120px;
    height: 40px;
    background: #3386FF;
    color: white;
    margin-top: 30px;
}

.mile {
    border: 1px solid #8F969F;
  }