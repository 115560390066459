.faqDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grayInfo {
    display: flex;
    width: 100%;
    padding: 12px 0px 12px 0px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #EEEEF2;
    color: #8F969F;
    font-size: 12px;
    line-height: 100%;
}

.faqDiv details {
    width: 100%;
}

.faqDiv summary {
    width: 100%;
    padding: 20px;
    color: #191919;
    list-style: none;
    /* border-top: 1px solid #EEEEF2; */
    border-bottom: 1px solid #EEEEF2;
    line-height: 100%;
    cursor: pointer;
}

.faqDiv details[open] summary {
    color: #8F969F;
}

.faqDiv summary::-webkit-details-marker, .faqDiv summary::marker {
    display: none !important;
}

.faqDiv details > div {
    width: 100%;
    padding: 10px 24px 10px 24px;
    font-size: 12px;
    border-bottom: 1px solid #EEEEF2;
    color: #31313A;
    white-space: pre-wrap;
}

@media (min-width: 1000px) {
    .faqDiv details > summary {
        font-size: 16px;
    }
    .faqDiv details > div {
        font-size: 14px;
    }
}