.mainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topBannerDiv {
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.topBannerInner {
  display: flex;
  overflow: visible;
  transition: transform 0.3s;
}

.topBannerInner img {
  width: 100vw;
}

.topBannerNext {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  line-height: 0;
  cursor: pointer;
}

.topBannerNextNext {
  transform: translate(550px, -25px);
}

.topBannerNextBefore {
  transform: translate(-600px, -25px) scaleX(-1);
}

.topBannerNext svg {
  width: 50px;
  height: 50px;
  fill: white;
}

.topBannerCircles {
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 2;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%);
  column-gap: 5px;
}

.topBannerCircle {
  margin: 0;
  padding: 0;
  line-height: 0;
  cursor: pointer;
}

.topBannerCircle.selected {
  cursor: default;
}

.topBannerCircle svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.rpDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rpWrapper {
  width: 1000px;
  overflow: hidden;
}

.rpContents {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  overflow: visible;
  transition: transform 0.3s;
}

.rpEachDiv {
  width: 490px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  cursor: pointer;
}

.rpEachDiv img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.rpEachText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.rpEachTitle {
  font-size: 18px;
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.rpEachPrice {
  font-size: 18px;
  color: #3386FF;
  font-weight: bold;
}

.rpTopDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.rpText {
  font-size: 26px;
  font-weight: bold;
}

.rpCircles {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.rpCircle, .rpArrow {
  margin: 0;
  padding: 0;
  line-height: 0;
  cursor: pointer;
}

.rpCircle svg {
  width: 20px;
  height: 20px;
  fill: #3386FF;
}

.rpArrow svg {
  width: 50px;
  height: 50px;
  fill: #3386FF;
}

.mainBottomDiv {
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 30px;
}

.mainBottomLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
  flex-shrink: 0;
}

.mainBottomLeftTitle {
  font-size: 26px;
  font-weight: bold;
}

.mainBottomLeftButtons {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.mainBottomLeftButtons button {
  width: 120px;
  height: 120px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000D;
  border: 1px solid #EAEAEA;
  border-radius: 20px;
}

.mainBottomLeftButtons > button > img {
  width: 65px;
  height: 65px;
  margin-bottom: 10px;
}

.mainBottomRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
  overflow: hidden;
}

.mainBottomRightTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mainBottomRightTopLeft {
  font-size: 26px;
  font-weight: bold;
}

.mainBottomRightTopRight {
  font-size: 16px;
}

.noticeListDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow: hidden;
}

.noticeListDiv a {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  color: #707070 !important;
}

.bottomBanner img {
  width: 1000px;
}