.withNav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 30px;
  align-items: flex-start;
  margin-top: 30px;
}

.navDiv {
  width: 180px;
  flex-shrink: 0;
  border: 1px solid #DCDCE6;
}

.navDiv > div {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #DCDCE6;
}

.navDiv > div:last-child {
  border: none;
}

.navDiv .title {
  background: #F8F8FA;
  font-size: 22px;
  font-weight: bold;
}

.navDiv .nav {
  font-size: 18px;
}

.navDiv .selected {
  color: #3386FF !important;
}

.contentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}