.asdf {
    width: 100%;
}

.react-datepicker {
    margin-top: 28px;
    width: 320px;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__day {
    width: 2.2rem;
    line-height: 2.2rem;
}

.react-datepicker__day-name {
    width: 2.2rem;
    font-size: 15px;
    line-height: 2.2rem;
}