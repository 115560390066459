.categoryDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.dropClickDiv {
    width: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dropClickDiv svg {
    width: 20px;
}

.dropListDiv {
    width: 80px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px #0000001A;
    border: 1px solid #D4D8DE;
    border-radius: 5px;
    box-sizing: border-box;
}

.dropListBtn {
    width: 100%;
    height: 35px;
    font-size: 14px;
}

.dropListBtn:hover {
    background: #F5F5F7;
}

.selectedDrop {
    font-weight: 700;
    color: #0052C8;
}

.infoLineDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
}

.prodNumDiv {
    font-size: 15px;
}

.prodNumColor {
    color: #0052C8;
    margin-left: 5px;
    font-weight: 700;
}

.loadmore {
    width: 150px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
    border: 1px solid #DCDCE6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noload {
    color: gray !important;
}

.eachProdLink {
    width: 100%;
}

.eachProdDiv {
    width: 100%;
    height: 80px;
    display: flex;
    flex-wrap: nowrap;
}

.prodImgDiv {
    width: 115px;
    height: 100%;
    flex-shrink: 0;
}

.prodImgDiv img {
    width: 100%;
    height: 100%;
}

.prodTextDiv {
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    padding-left: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.prodCateDiv {
    font-size: 16px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.prodDescDiv {
    width: 100%;
    padding-right: 10px;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.prodPriceWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
}

.prodPriceDiv {
    padding-left: 5px;
    border-bottom: 1px solid #FF4E40;
    font-size: 14px;
    color: #FF4E40;
}

.line {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #EEEEF2;
    margin-top: 20px;
    margin-bottom: 20px;
}