.mainDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageSlideDiv {
    width: 100vw;
    position: relative;
}

.slideEachImage {
    width: 100vw;
}

.indicatorDiv {
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 8px;
    display: flex;
    justify-content: center;
}

.indicatorInner {
    width: 50%;
    height: 100%;
    background: #FFFFFF70;
    display: flex;
}

.eachIndicator {
    width: 100%;
    height: 100%;
    flex-shrink: 1;
}

.eachIndicator.indiActive {
    background: white;
}

.mainTextDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 21px;
    line-height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    row-gap: 5px;
}

.cateLinkDiv {
    width: 100%;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, 75px);
    justify-content: space-between;
    grid-gap: 10px;
}

.cateLinkDiv::after {
    content: "";
    flex: auto;
}

.cateEachDiv {
    width: 75px;
    height: 75px;
    background: #F4FAFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    font-size: 12px;
}

.cateEachDiv img {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

.bottomBanner {
    margin-top: 60px;
    width: 100vw;
}

.bottomBanner:last-of-type {
    margin-top: 30px !important;
}