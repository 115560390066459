.blurDiv {
    width: 100vw;
    height: 100vh;
    background: #00000031;
    opacity: 0;
    backdrop-filter: blur(1px);
    position: fixed;
    z-index: -1;
    /* transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s; */
}

.blurOn {
    opacity: 1;
    z-index: 12;
}

.menuDiv {
    position: absolute;
    top: 60px;
    width: 300px;
    left: -360px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 0px 0px 30px 0px;
    opacity: 0.9;
    z-index: 13;
    transition: left 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuOn {
    left: 0;
}

.menuInner {
    box-sizing: border-box;
    width: 260px;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 30px;
}

.loginTextLineDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loginTextLineDiv div {
    font-size: 15px;
}

.loginTextLineDiv button {
    padding: 0;
    background: none;
    border: 0;
}

.loginTextLineDiv svg {
    width: 15px;
    height: 15px;
}

.buttonsDiv {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.buttonsDiv a {
    width: 125px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3386FF;
    background-color: #FFFFFF;
}

.littleMenuDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.iconTitleDiv {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 15px;
    transition: color 0.3s;
}

.showDetail .littleMenuDiv {
    color: #0068FF;
}

.iconTitleDiv img {
    width: 25px;
    margin-right: 7px;
}

.littleMenuDiv svg {
    transition: fill 0.3s, transform 0.3s;
}

.showDetail .littleMenuDiv svg {
    fill: #0068FF;
    transform: rotate(-180deg);
}

.littleMenuDiv svg {
    width: 15px;
}

.menuDistinct {
    border-bottom: 1px solid #C2C2C2;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    transition: border-color 0.3s;
}

.showDetail .menuDistinct {
    border-bottom-color: #E3E3E3;
}

.littleList {
    width: 100%;
    overflow: hidden;
    padding-left: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 15px;
    transition: height 0.3s, margin 0.3s;
    flex-shrink: 0;
}

.littleList:not(.showDetail .littleList) {
    height: 0 !important;
    margin: 0;
}

.littleList a {
    font-size: 14px;
    margin-top: 9px;
}