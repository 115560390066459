.popularListDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 16px;
  margin-top: 30px;
  margin-bottom: 100px;
}

.popularDiv {
  width: 23.8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}

.popularDiv img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.popularTitle {
  font-size: 18px;
  letter-spacing: -0.18px;
  margin-top: 18px;
  text-align: left;
  line-height: 24px;
  min-height: 75px;
}

.popularDesc {
  margin-top: 10px;
  font-size: 16px;
  color: #8F969F;
  letter-spacing: -0.16px;
  line-height: 19px;
  min-height: 60px;
  text-align: left;
}

.popularPrice {
  font-size: 23px;
  font-weight: bold;
  color: #3386FF;
  letter-spacing: -0.23px;
  margin-top: 10px;
}

.typeSelectDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8F969F;
}

.typeTitle {
  font-size: 26px;
  font-weight: bold;
  padding-right: 10px;
  border-bottom: 5px solid #3386FF;
}

.orderListDiv {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  margin-right: 10px;
}

.orderDiv {
  font-size: 18px;
  cursor: pointer;
  color: #9A9A9A;
}

.selectedOrder {
  color: black;
  cursor: default;
}

.productList {
  width: 100%;
  display: flex;
  flex-direction: column;
}