.customTable {
  width: 100%;
  border-collapse: collapse;
}

.customTable thead td {
  border-top: 2px solid #8F969F;
  background: #F8F8FA;
  font-size: 20px;
  color: #425571;
  font-weight: bold;
}

.customTable tbody td {
  font-size: 18px;
  color: #3E3E3E;
}

.customTable tr {
  height: var(--eachHeight);
}

.customTable td {
  text-align: center;
  border-bottom: 1px solid #DCDCE6;
}

.verticalLine td {
  border-right: 1px solid #DCDCE6;
}

.verticalLine td:last-child {
  border-right: 0;
}