.title {
  width: 100%;
  text-align: center;
  padding: 20px 0px 20px 0px;
  border: 1px solid #DCDCE6;
  background: #FDFDFE;
  font-size: 22px;
}

.blank {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
}