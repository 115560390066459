.mypageDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mypageLinkDiv {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
}

.mypageGrayTitle {
    margin-bottom: 20px;
    color: #B6BAC0;
}

.mypageProfileDiv {
    width: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
}

.mypageProfileName {
    font-size: 17px;
}

.mypageProfileBtn {
    padding: 10px 15px 10px 15px;
    border: 1px solid #DCDCE6;
    border-radius: 8px;
    line-height: 100%;
    color: #95999F;
}

.mypageLine {
    width: 100%;
    height: 1px;
    background: #EEEEF2;
    margin-top: 40px;
    margin-bottom: 20px;
}

.mypageLinkList {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 24px;
}

.mypageLinkList a {
    width: 45%;
    line-height: 100%;
    font-size: 15px;
}

.mypageBottomLinkDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}

.mypageBottomLinkDiv div {
    width: 1px;
    height: 14px;
    background: #DCDCE6;
}

.mypageBottomLinkDiv a {
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B6BAC0 !important;
}