.productDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imagesDiv {
    width: 100vw;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.imagesDiv::-webkit-scrollbar {
    display: none;
}

.slideEachImage {
    width: 100vw;
    flex-shrink: 0;
    scroll-snap-align: start;
}

.productTitle {
    width: 100%;
    display: flex;
    font-size: 18px;
    color: #191919;
    margin-top: 20px;
}

.productDesc {
    width: 100%;
    color: #8F969F;
    margin-top: 12px;
}

.productLine {
    width: 100%;
    height: 1px;
    background: #EEEEF2;
    margin-top: 20px;
    margin-bottom: 30px;
}

.productLittleTitle {
    width: 100%;
    color: #191919;
    font-size: 16px;
    line-height: 100%;
}

.monthPickerDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.monthPickerDiv button {
    padding: 0;
    margin: 0;
    border: 0;
    width: 28px;
    height: 28px;
}

.monthPickerDiv svg {
    width: 28px;
    height: 28px;
    fill: #9A9A9A;
}

.monthPickerDiv button:last-of-type svg {
    transform: scaleX(-1);
}

.monthPickerText {
    font-size: 16px;
}

.btnDisable svg {
    fill: transparent
}

.dotInfoDiv {
    width: 320px;
    display: flex;
    justify-content: space-around;
    color: #4F5968;
    font-size: 12px;
    margin-top: 5px;
}

.dotInfoDiv > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.dotInfoDiv > div > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.productDateListDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 300px;
    overflow: scroll;
    margin-top: 20px;
}

.productDateListDiv::-webkit-scrollbar {
    display: none;
}

.eachDateDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #DCDCE6;
}

.productDateListDiv a:last-of-type .eachDateDiv {
    border: none !important;
}

.selectedDate {
    background: #3386FF;
    color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 8px #0052C84D;
}

.eachDateText {
    flex-shrink: 0;
}

.dateStatusTag {
    padding: 3px 10px 3px 10px;
    margin-left: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.eachDateInfoDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.eachDateInfoDiv > div {
    width: 100%;
    display: flex;
    column-gap: 25px;
}

.eachDateInfoDiv > div > div:first-child {
    color: #31313A;
}

.eachDateInfoDiv > div > div:last-child {
    color: #4F5968;
}

.eachDateInfoDiv > div > div:last-child > div {
    display: flex;
    align-items: center;
}

.eachTimePlace {
    font-size: 8px;
    height: 16px;
    border: 1px solid;
    line-height: 100%;
    margin-left: 10px;
    padding: 2px;
}

.eachTimeKorea {
    border-color: #0052C8;
    color: #0052C8;
}

.eachTimeAbroad {
    border-color: #FF4E40;
    color: #FF4E40;
}

.priceTable {
    width: 100%;
    margin-top: 20px;
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
}

.priceTable thead {
    background: #F8F8FA;
}

.priceTable td {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEF2;
}

.calendar {
    width: 100%;
}

.calenDayDiv {
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 15px;
}

.calenDayDiv div {
    position: absolute;
    width: 6px;
    height: 6px;
    right: 3px;
    top: 3px;
    border-radius: 50%;
    background: var(--dotColor);
}

.dayDisabled {
    color: #ccc;
}

.selectHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    border-bottom: 1px solid #3386FF;
    border-collapse: collapse;
}

.selectHeader > button {
    width: 100%;
    border: 1px solid #DCDCE6;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #425571;
    border-bottom: 0;
}

.selectedInfo {
    background: #3386FF !important;
    color: white !important;
}

.infoPageCaution {
    background: #F8F8FA;
    padding: 15px;
}

.infoPageInfo {
    width: 100%;
}

.infoPageInfoContents {
    background: #FCFCFC;
    padding: 15px;
}

.infoOpenDiv {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F8FA;
    border-top: 1px solid #DCDCE6;
    border-bottom: 1px solid #DCDCE6;
    position: relative;
    color: #4F5968;
}

.infoOpenDiv svg {
    position: absolute;
    width: 24px;
    height: 24px;
    transform: rotate( -90deg );
    right: 10px;
    fill: #9A9A9A;
}

.infoOpenDiv::-webkit-details-marker {
    display: none;
}

.reserveBtnDiv {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
}

.reserveBtn {
    height: 45px;
    line-height: 100%;
    font-size: 15px;
    color: #FFFFFF !important;
    background: #3386FF;
    padding: 0px 40px 0px 40px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.likeBtn {
    position: absolute;
    height: 100%;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    right: 10px;
    line-height: 100%;
    font-size: 12px;
    color: #425571;
}

.likeBtn svg {
    width: 30px;
    height: 30px;
    fill: #425571;
}