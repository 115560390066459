.eachNoticeDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoDiv {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    border-bottom: 1px solid #EEEEF2;
    margin-bottom: 10px;
}

.infoTitleDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    word-break: break-all;
    text-align: center;
}

.infoInfoDiv {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    font-size: 12px;
}

.contentsDiv {
    width: 100%;
    white-space: pre-wrap;
}

.eachNoticeDiv form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    row-gap: 10px;
}

.eachNoticeDiv form div {
    font-size: 16px;
}

.eachNoticeDiv form input {
    width: 100%;
    border: 1px solid #DCDCE6;
    text-align: center;
    font-size: 16px;
}

.eachNoticeDiv form button {
    background: #3386FF;
    color: white;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
}

.deleteButton {
    background: #425571;
    color: white;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    margin-top: 40px;
}

.newQNADiv {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    width: 80%;
    margin-top: 30px;
}

.newQNADiv input {
    width: 100%;
    border: 1px solid #DCDCE6;
    text-align: center;
    font-size: 16px;
}

.newQNADiv textarea {
    width: 100%;
    border: 1px solid #DCDCE6;
    text-align: center;
    font-size: 16px;
    height: 150px;
}

@media (min-width: 1000px) {
    .infoInfoDiv {
        font-size: 14px;
    }

    .contentsDiv {
        font-size: 16px;
    }
}