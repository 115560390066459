.littleTitle {
  width: 100%;
  font-size: 20px;
  padding-left: 6px;
  border-bottom: 2px solid #8F969F;
  text-align: left;
  color: #425571;
  font-weight: bold;
  margin-bottom: 30px;
}

.wrapper {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.priceDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  row-gap: 10px;
}

.priceDiv div {
  font-weight: bold;
}

.priceDiv span {
  color: #FF4E40;
  margin-left: 10px;
  margin-right: 10px;
}

.peopleDiv {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #425571;
}

.payBtn {
  font-size: 24px;
  width: 180px;
  height: 60px;
  background: #3386FF;
  color: white;
}

.mile {
  border: 1px solid #8F969F;
}

.mileText {
  font-size: 20px;
  width: 100%;
  text-align: left;
  margin-left: 100px;
  margin-top: 10px;
}