.footerDiv {
    width: 100vw;
    margin-top: 30px;
}


.contactDiv {
    width: 100%;
    padding: 20px;
    background: #F7F7F9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 100%;
}

.logo {
    width: 87px;
    margin-bottom: 14px;
}

.contactPhone {
    font-size: 14px;
    color: #616161;
    margin-bottom: 5px;
}

.workAndIconDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.workTimeDiv {
    color: #9A9A9A;
    font-size: 11px;
}

.iconDiv {
    display: flex;
    column-gap: 10px;
}

.iconDiv img {
    width: 30px;
    height: 30px;
}

.infoDiv {
    width: 100%;
    padding: 20px;
    padding-bottom: 35px;
    background: #F0F0F2;
    display: flex;
    flex-direction: column;
}

.infoAlwaysDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.infoLinksDiv {
    display: flex;
    line-height: 100%;
    margin-bottom: 13px;
}

.infoLinksDiv div > * {
    font-size: 11px;
    color: #616161;
}

.divideLine {
    margin-left: 10px;
    margin-right: 10px;
}

.rightsAndOpenDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightsAndOpenDiv div {
    color: #9A9A9A;
    font-size: 10px;
}

.rightsAndOpenDiv button {
    font-size: 11px;
    color: #616161 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightsAndOpenDiv svg{
    width: 16px;
    fill: #707070;
}

.infoDetailDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 11px;
    color: #9A9A9A;
    line-height: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
}

.detailShow .infoDetailDiv {
    max-height: 140px !important;
}

.detailContactDiv {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    margin-top: 20px;
}

.companyInfoDiv {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 18px;
}

.companyInfoDiv svg {
    width: 12px;
    height: 12px;
    fill: #9A9A9A;
    margin-right: 3px;
}

.companyInfoDiv div {
    display: flex;
}