.productWrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 30px;
  align-items: flex-start;
  margin-top: 50px;
}

.productSideDiv {
  width: 300px;
  flex-shrink: 0;
  position: sticky;
  top: 30px;
  padding: 30px;
  border: 2px solid #DCDCE6;
}

.sideLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sideTitle {
  font-size: 24px;
  font-weight: bold;
}

.likeBtn {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  line-height: 100%;
  font-size: 12px;
  color: #3386FF;
}

.likeBtn svg {
  width: 30px;
  height: 30px;
  fill: #3386FF;
}

.sideDate {
  font-size: 18px;
}

.sideDash {
  width: 100%;
  border-bottom: 2px dashed #DCDCE6;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidePeople {
  font-size: 18px;
}

.sideBtn {
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: white;
  background: #3386FF;
  margin-top: 20px;
}

.productDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bigTitle {
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: bold;
}

.titleDesc {
  font-size: 16px;
  color: #8F969F;
  margin-bottom: 45px;
}

.productImg {
  width: 100%;
}

.miniTitle {
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #DCDCE6;
  width: 100%;
  text-align: left;
}

.dateWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftRightWrapper {
  width: 100%;
  padding: 20px;
}

.time {
  margin-right: 16px;
}

.time span {
  font-size: 14px;
  padding: 5px;
  border: 1px solid;
  margin-right: 8px;
}

.blue {
  border-color: #3386FF;
  color: #3386FF;
}

.red {
  border-color: #FF4E40;
  color: #FF4E40;
}

.htmlContent {
  width: 100%;
  padding: 20px;
}

.section {
  width: 100%;
}

.links {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #DCDCE6;
  position: sticky;
  top: 0px;
  background: white;
}

.links button {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.links .selected {
  color: #3386FF;
  border-bottom: 5px solid #3386FF;
}