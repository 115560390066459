.reserveWrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 30px;
    align-items: flex-start;

}  

.reserveDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 0px 20px;
}

.reserveSideDiv {
    margin-top: 50px;
    display: none;
    width: 300px;
    flex-shrink: 0;
    border: 2px solid #DCDCE6;
    flex-grow: 0;
    padding: 20px;
    position: sticky;
    top: 30px;
}

@media (min-width: 1000px) {
    .reserveSideDiv {
        display: block;
    }
}

.sideTitle {
    font-size: 20px;
    font-weight: bold;
    line-height: 130%;
}

.sideDesc {
    font-size: 16px;
    color: #8F969F;
    line-height: 130%;
}

.sideDateStart {
    width: 100%;
    text-align: left;
}

.sideDateEnd {
    width: 100%;
    text-align: right;
}

.sideImg {
    width: 100%;
}

.innerScreen {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.innerTitle {
    width: 100%;
    color: #191919;
    font-size: 18px;
    margin-bottom: 20px;
}

.line {
    width: 100%;
    height: 1px;
    background: #EEEEF2;
    margin: 20px 0px 30px 0px ;
}

.nextBtn {
    width: 200px;
    height: 45px;
    color: white;
    border-radius: 23px;
    margin-top: 50px;
}

.optionsListDiv {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    row-gap: 15px;
}

.optionSelectEach {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    align-items: center;
}

.optionName {
    font-size: 16px;
    color: #4F5968;
    line-height: 100%;
}

.optionNumInfoDiv {
    display: flex;
    column-gap: 5px;
}

.optionBtn {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    line-height: 100%;
    font-size: 16px;
}

.optionBtn svg {
    width: 22px;
    height: 22px;
    fill: #3386FF;
}

.optionBtnDisable svg {
    fill: #DCDCE6 !important;
}

.optionCount {
    color: #191919;
    width: 30px;
    display: flex;
    justify-content: center;
}

.eachOptionPeopleDiv {
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEF2;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.eachOptionPeopleDiv:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.eachOptionTitleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
}

.eachOptionTitleTitle {
    font-size: 16px;
    color: #4F5968;
}

.eachOptionTitleMax {
    font-size: 12px;
    color: #83878d;
}

.eachOptionNumListDiv {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-right: 10px;
}

.eachPeopleInfoDiv {
    width: 100%;
    padding: 0px 10px 30px 10px;
    border-bottom: 1px solid #EEEEF2;
    margin-bottom: 30px;
}

.eachPeopleInfoDiv:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
}

.eachDisplayName {
    font-size: 16px;
    color: #191919;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eachAgreeCheckDiv {
    display: flex;
    font-size: 14px;
    color: #4F5968;
    column-gap: 5px;
}

.eachAgreeCheckDiv svg {
    width: 22px;
    height: 22px;
}

.checkOn svg {
    fill: #3386FF;
}

.eachPeopleFormDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}