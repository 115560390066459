.loadmore {
    width: 150px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
    border: 1px solid #DCDCE6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.noload {
    color: gray !important;
    cursor: default;
}
