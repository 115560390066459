.noticeDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grayTitleDiv {
    width: 100%;
    display: flex;
    padding: 12px 0px 12px 0px;
    border-bottom: 1px solid #EEEEF2;
}

.grayTitleDiv div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #8F969F;
    line-height: 100%;
}

.grayTitle {
    width: 100%;
}

.grayDate {
    width: 100px;
    flex-shrink: 0;
}

.noticeArticleDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.noticeEachArticleDiv {
    width: 100%;
    display: flex;
    height: 60px;
    align-items: center;
    border-bottom: 1px solid #EEEEF2;
}

.badgeAndTitle {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 13px;

    overflow: hidden;
}

.noticeBadge {
    line-height: 100%;
    font-size: 12px;
    padding: 6px;
    border: 1px solid #6095E1;
    color: #6095E1;
    flex-shrink: 0;
}

.answerBadge {
    border: 1px solid #FF4E40;
    color: #FF4E40;
    margin-left: 20px;
}

.noticeTitle {
    color: #4F5968;
    text-overflow: ellipsis;
    overflow: hidden;
}

.noticeInfo {
    width: 100px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    row-gap: 7px;
    font-size: 12px;
}

.deleteButton {
    background: #425571;
    color: white;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    margin-top: 20px;
}

@media (min-width: 1000px) {
    .noticeTitle {
        font-size: 16px;
    }
}