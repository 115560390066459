.signupDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signupSubTopBarDiv {
    width: 100vw;
    display: flex;
    background: #FCFCFC;
    height: 40px;
}

@media (min-width: 1000px) {
    .signupSubTopBarDiv {
        width: 120%;
    }
}


.signupSubTopBarDiv div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDCE6;
    border-radius: 0px 25px 25px 0px;
    border-left: 0 !important;
    width: 100%;
    flex-shrink: 1;
    padding-left: 12px;
    box-sizing: border-box;
}

.signupSubTopBarDiv div:nth-child(1) {
    padding-left: 0;
    z-index: 4;
}

.signupSubTopBarDiv div:nth-child(2) {
    margin-left: -25px;
    flex-shrink: 0.95 !important;
    z-index: 3;
}

.signupSubTopBarDiv div:nth-child(3) {
    margin-left: -25px;
    border-right: 0 !important;
    border-radius: 0 !important;
    z-index: 2;
}

.nowStep {
    background: white !important;
    border-color: #3386FF !important;
    color: #3386FF;
}

.agreeDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
}

.agreeDiv svg {
    width: 23px;
    height: 23px;
}

.agreeSelected svg {
    fill: #3386FF !important;
}

.agreeAllCheckDiv {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.agreeAllCheckDesc {
    width: 100%;
    padding-left: 30px;
    letter-spacing: -0.3px;
    color: #888888;
    font-size: 12px;
    margin-top: 10px;
}

.agreeLine {
    width: 100%;
    height: 1px;
    background: #EEEEF2;
    margin-top: 20px;
    margin-bottom: 20px;
}

.agreeListDiv {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.eachAgreeDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;
}

.eachAgreeCheckDiv {
    width: 100%;
    display: flex;
    column-gap: 5px;
}

.eachAgreeContentsDiv {
    width: calc(100% - 30px);
    height: 150px;
    overflow: scroll;
    border: 1px solid #EEEEF2;
    padding: 14px;
    white-space: pre-wrap;
    font-size: 12px;
}

.agreeNextBtn {
    width: calc(100% - 30px);
    background: #8F969F;
    border-radius: 3px;
    height: 45px;
    color: white !important;
    margin-top: 50px;
}

.agreeNextAvail {
    background: #3386FF !important;
}

.writeInfoDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.writeInfoTitle {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    width: 100%;
}

.writeInfoDiv form {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.eachFormDiv {
    width: 100%;
    display: flex;
}

.eachFormTitle {
    width: 40%;
    height: 40px;
    display: flex;
    align-items: center;
    color: #4F5968;
}

.eachFormChild {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.eachFormChild input:not(input[type="radio"]) {
    width: 100%;
    height: 40px;
    padding: 10px;
    background: #F5F5F7;
    border: 0;
    border-radius: 8px;
    color: #8F969F;
}

.eachFormDesc {
    font-size: 12px;
    color: #8F969F;
}

.formsRadioDiv {
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 40px;
}



.formsRadioDiv div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.idAvailDiv {
    display: none;
}

.idAvailYes, .idAvailNo {
    display: block !important;
    font-weight: bold;
}

.idAvailYes {
    color: #3386FF;
}

.idAvailNo {
    color: crimson;
}

.writeInfoDiv form button {
    width: 100%;
    height: 45px;
    background: #3386FF;
    color: white !important;
    border-radius: 3px;
    margin-top: 45px;
    font-size: 16px;
}

.loginSocialText {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.completeDiv {
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.completeTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 22px;
    color: #191919;
}

.completeDiv svg {
    width: 100px;
    height: 100px;
    fill: #3386FF;
    margin-top: 25px;
    margin-bottom: 50px;
}

.completeDesc {
    font-size: 18px;
}

.completeBtnDiv {
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 130px;
}

.completeBtnDiv div {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: white;
    border-radius: 23px;
}