.footerDiv {
  margin-top: 150px;
  width: 100vw;
  background: #F8F8FA;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #616161;
}

.footerInner {
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.leftLinks {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  margin-bottom: 30px;
  line-height: 100%;
}

.leftInfos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 55px;
}

.leftInfos img {
  width: 160px;
}

.leftInfoDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.leftInfoTop {
  white-space: pre-wrap;
  line-height: 28px;
}

.leftCopyright {
  margin-top: 30px;
  color: #9A9A9A;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 100%;
}

.rightTitle {
  margin-bottom: 18px;
  letter-spacing: 0.28px;
}

.rightPhone {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px;
}

.rightDay {
  letter-spacing: 0.28px;
  margin-bottom: 10px;
}

.rightLinks {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 12px;
}

.rightLinks img {
  width: 40px;
  height: 40px;
}